import $ from "jquery";
import "what-input";
import "bootstrap";
import AOS from "aos";

window.jQuery = $;





(function () {

  AOS.init({
    duration: 300,
    offset: 100,
    easing: 'ease-in',
    once: true
  });

})();
